@use "../../1-core/" as *;

$landing-page-multistep-modal-cta-width: 348px;

.landing-page-multistep-modal-cta {
    position: fixed;
    z-index: 999;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: $white;
    border-radius: $spacing-1;
    @include light-shadow('lg');


    &.show-on-scroll,
    &.show-with-delay {
        visibility: hidden;
        opacity: 0;
        transition: opacity 500ms ease-in;
    }

    .landing-page-multistep-modal-btn {
        width: max-content;
    }

    .landing-page-multistep-modal-header {
        display: flex;
        justify-content: space-between;

        .author-name {
            @include text-bold;
        }

        .landing-page-multistep-modal-close {
            border: none;
            background: none;

            .svg-icon {
                color: $black;
            }
        }
    }

    .landing-page-multistep-modal-content {
        display: flex;
        flex-direction: column;
        gap: $spacing-4;
        align-items: flex-start;
        transition: height 400ms, opacity 800ms ease-in;

        form {
            display: flex;
            flex-direction: column;
            padding-top: $spacing-3;
            padding-bottom: $spacing-2;
            align-self: stretch;
            gap: $spacing-4;

            label {
                @include text-xs;
                color: $black;
            }

            .landing-page-multistep-modal-btn {
                margin-top: $spacing-4;
            }

            // Remove the spin button from number input

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }
        }

        .landing-page-multistep-modal-btn.hidden {
            display: none;
        }
    }

    .landing-page-multistep-modal-content + .landing-page-multistep-modal-content {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        height: 0;
    }

    .notification {
        padding: $spacing-5;

        &.hidden {
            display: none;
        }
    }
}

.landing-page-multistep-modal-overlay {
    width: 0;
    height: 0;
    display: none;
    position: fixed;
    background: rgba($black, 0.8);
    z-index: map-get($z-index, modal);

    &.show {
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}

@media (max-width: $screen-sm-max) {
    .landing-page-multistep-modal-cta {
        &.teal-theme {
            background-color: $color-1-50;

            .landing-page-multistep-modal-header {
                background-color: $color-1-50;
            }
        }

        &.open {
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            width: 90%;
            height: max-content;
        }

        .landing-page-multistep-modal-header {
            margin: $spacing-4 $spacing-4 0 $spacing-4;
            padding-bottom: $spacing-4;
            border-bottom: 1px solid $grey-1-100;

            .author-avatar {
                width: $spacing-6;
                margin-right: $spacing-3;
            }

            .author-name {
                @include text-xs;
            }

            .author-info {
                display: flex;
                gap: $spacing-2;
                align-items: center;
            }
        }

        .landing-page-multistep-modal-content {
            padding: $spacing-4;

            &.step-one {
                flex-direction: row;
                gap: $spacing-2;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

@media (min-width: $screen-md) {
    .landing-page-multistep-modal-cta {
        padding: $spacing-5;
        width: $landing-page-multistep-modal-cta-width;
        border-radius: $spacing-2;
        bottom: $spacing-5;
        right: $spacing-5;

        .intro-text {
            @include text-md;
        }

        .landing-page-multistep-modal-header {
            position: relative;
            margin-bottom: $spacing-4;

            .landing-page-multistep-modal-close {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}
